import React from "react";
import Navbar from "../../components/Navbar";
import Brackground from "../../components/Background";
import Container from "../../components/Container";
import Header from "../../components/Header";
import style from "./PesagemAvulsa.module.css";
import { useNavigate, useParams } from "react-router-dom";
import Input from "../../components/Input";
import SubmitButton from "../../components/Button";
import { useState, useEffect } from "react";
import Axios from "axios";
import { SnackbarProvider, useSnackbar } from "notistack";
import moment from "moment";
import jsPDF from "jspdf";
import JC7Logo from "./Logo-JC7.png";
import Modal from "react-modal"; // Certifique-se de instalar react-modal
import modalStyles from "./Modal.module.css"; // Estilos específicos para o modal

const PesagemAvulsa = () => {
  const navigate = useNavigate();

  useEffect(() => {
    getBigBag();
    getOperacoes();
    getOperacao();
    getTransp();
    fetchPlacas(cpf);
  }, []);

  const fetchPlacas = async (cpf) => {
    try {
      const response = await Axios.get(
        `https://portal.jc7transportes.com.br/api/pesageminicial/historico/${cpf}`
      );
      if (response.data.length > 0) {
        const {
          PLACA_CAVALO,
          PLACA_CARRETA,
          PLACA_CARRETA2,
          PLACA_CARRETA3,
          TIPO_VEICULO,
        } = response.data[0]; // Destructure the response
        setPlacacavalo(PLACA_CAVALO);
        setPlaca1(PLACA_CARRETA || ""); // Set or default to empty string
        setPlaca2(PLACA_CARRETA2 || "");
        setPlaca3(PLACA_CARRETA3 || "");
      }
    } catch (error) {
      console.error("Error fetching vehicle plate data:", error);
    }
  };

  const [tipopesagem, setTipopesagem] = useState("G");
  const [disabled, setDisabled] = useState(false);
  const [navio, setNavio] = useState("");
  const [bruto, setBruto] = useState("");
  const [tara, setTara] = useState("");
  const [pesoLiquido, setPesoLiquido] = useState(null);

  const [qtdabigbag, setQtdaBigBag] = useState("");
  const [pesobigbag, setPesoBigBag] = useState("");
  const [bigbags, setBigBags] = useState([]);
  const [numerolacre, setNumeroLacre] = useState("");
  const [numeroconteiner, setNumeroConteiner] = useState("");

  const [placacavalo, setPlacacavalo] = useState("");
  const [cliente, setCliente] = useState("");
  const [produto, setProduto] = useState("");
  const [placa1, setPlaca1] = useState("");
  const [placa2, setPlaca2] = useState(null);
  const [placa3, setPlaca3] = useState(null);

  const [operacoesList, setOperacoesList] = useState([]);
  const [pedidosByDoc, setPedidosByDoc] = useState([]);
  const [docs, setDocs] = useState([]);
  const [pedidoMic, setPedidoMic] = useState([]);
  const [destino, setDestino] = useState("");

  const [doc, setDoc] = useState("");
  const [porcentagem, setPorcentagem] = useState(null);
  const [saldoTons, setSaldoTons] = useState(null);
  const [transportadora, setTransportadora] = useState([]);
  const [transportadoras, setTransportadoras] = useState([]);
  const [data, setData] = useState("");
  const [carregamentoDetails, setCarregamentoDetails] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [fluxoConteiner, setFluxoConteiner] = useState("");

  const [cpfMotorista, setCpfMotorista] = useState(""); // CPF do motorista
  const [nomeMotorista, setNomeMotorista] = useState(""); // Nome do motorista
  const [codMotorista, setCodMotorista] = useState(null); // Código do motorista
  const [showModal, setShowModal] = useState(false); // Para mostrar o modal, caso necessário
  const [cpfSemMascara, setCpfSemMascara] = useState(""); // CPF sem máscara
  const [tipoPeso, setTipoPeso] = useState([]);

  const usuario = JSON.parse(localStorage.getItem("user_token")).id;

  const validaPlaca = (placa) => {
    // Expressão regular para validar placas no formato antigo (AAA1234) ou Mercosul (AAA1B23)
    const placaRegex =
      /^[A-Z]{3}\d{4}$|^[A-Z]{3}\d{1}[A-Z]{1}\d{2}$|^[A-Z]{3}\d{2}[A-Z]{1}\d{1}$/;

    return placaRegex.test(placa);
  };

  const validaDados = async () => {
    // Campos comuns obrigatórios
    if (!placacavalo || !transportadora || !tipopesagem) {
      showAlert("Preencha todos os campos obrigatórios.", "error");
      return;
    }

    // Validações de placa
    if (!validaPlaca(placacavalo)) {
      showAlert(
        "Placa do cavalo deve estar no formato correto (Ex: AAA1234 ou AAA1B23)",
        "error"
      );
      return;
    }

    if (
      (placa1 && !validaPlaca(placa1)) ||
      (placa2 && !validaPlaca(placa2)) ||
      (placa3 && !validaPlaca(placa3))
    ) {
      showAlert(
        "Placas devem estar no formato correto (Ex: AAA1234 ou AAA1B23)",
        "error"
      );
      return;
    }

    // Se tudo estiver correto, chama a função addPesagem
    addPesagem();
  };

  const validaTecla = (e) => {
    if (e.key === "Enter") {
      validaDados();
    }
  };

  const validaTipo = (val) => {
    if (val === "G") {
      showAlert("Pesagem a GRANEL selecionada com sucesso!", "success");
      setDisabled(false);
      setBruto(0);
    } else if (val === "B") {
      showAlert("Pesagem em BIG BAG selecionada com sucesso!", "success");
      setDisabled(false);
      setBruto(0);
    } else {
      showAlert("Pesagem em Container selecionada com sucesso!", "success");
      setBruto(1000);
      setDisabled(true);
    }
  };

  const validaFluxo = (val) => {
    if (val === "SAÍDA") {
      showAlert("Pesagem de SAÍDA selecionada com sucesso!", "success");
      setDisabled(false);
      setBruto(0);
    } else {
      showAlert("Pesagem de ENTRADA selecionada com sucesso!", "success");
      setBruto(1000);
      setDisabled(true);
    }
  };

  const getOperacao = (idCarregamento, payload) => {
    Axios.get(
      `https://portal.jc7transportes.com.br/api/periodo/carregamento/${idCarregamento}`
    )
      .then((response) => {
        console.log(response.data);

        generatePdf({ ...payload, ID_CARREGAMENTO: idCarregamento });
        // Notificação de início do processo
        showAlert("Iniciando a geração do PDF.", "info");
      })
      .catch((error) => {
        console.error("Erro ao buscar operações:", error);
      });
  };

  const addPesagem = () => {
    const dataCadastro = getDate(); // Define a data atual no momento do clique

    let payload = {
      PLACA_CAVALO: placacavalo, // Placa do cavalo
      COD_MOTORISTA: codMotorista, // Código do motorista
      PLACA_CARRETA: placa1, // Placa da carreta 1
      PLACA_CARRETA2: placa2, // Placa da carreta 2
      PLACA_CARRETA3: placa3, // Placa da carreta 3
      COD_TRANSP: transportadora, // Código da transportadora
      STATUS_CARREG: "1", // Status do carregamento
      USUARIO: usuario, // ID do usuário
      DATA_CADASTRO: dataCadastro, // Data do cadastro
      TIPO_PESAGEM: tipopesagem, // Tipo de pesagem (G, B, C)
      CLIENTE: cliente || null, // Novo campo CLIENTE (adicionado no payload)
      PRODUTO: produto || null, // Novo campo PRODUTO (adicionado no payload)
      PESO_BRUTO: bruto ? parseFloat(bruto) : null,
      PESO_TARA: tara ? parseFloat(tara) : null,
      COD_TIPO_PESAGEM: "1",
    };

    // Envia o payload para a API
    Axios.post(
      "https://portal.jc7transportes.com.br/api/pesagem/pesagemavulsa",
      payload
    )
      .then((res) => {
        if (res.data.success) {
          const idCarregamento = res.data.ID_CARREGAMENTO; // ID do carregamento
          showAlert(
            `Pesagem cadastrada com sucesso! ID: ${idCarregamento}`,
            "success"
          );
          // Função para aguardar 2 segundos antes de redirecionar
          const delay = (ms) =>
            new Promise((resolve) => setTimeout(resolve, ms));

          // Aguarda 2 segundos antes de redirecionar
          delay(2000).then(() => {
            navigate("/pesagemavulsa/historicopesagemavulsa");
          });

          getCarregamentoDetails(idCarregamento); // Recupera os detalhes do carregamento
        } else {
          showAlert(
            "Erro ao cadastrar a pesagem. Por favor, tente novamente.",
            "error"
          );
        }
      })
      .catch((error) => {
        console.error("Erro ao cadastrar a pesagem:", error);
        showAlert("Ocorreu um erro ao cadastrar a pesagem.", "error");
      });
  };

  const getCarregamentoDetails = (idCarregamento) => {
    Axios.get(
      `https://portal.jc7transportes.com.br/api/pesagem/carregamento/${idCarregamento}`
    )
      .then((response) => {
        console.log(response.data); // Verifique os dados aqui
        if (response.data && response.data.length > 0) {
          setCarregamentoDetails(response.data[0]); // Acesse o primeiro item do array
        }
        setModalIsOpen(true); // Abre o modal
      })
      .catch((error) => {
        console.error("Erro ao buscar dados do carregamento:", error);
        showAlert("Erro ao carregar informações do carregamento.", "error");
      });
  };

  const closeModal = () => {
    navigate("/veiculos/BuscarMotorista");
  };

  const generatePdf = (carregamento) => {
    if (carregamento.STATUS_CARREG === 7) return;

    const pdf = new jsPDF();
    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();
    const logoWidth = 50;
    const logoHeight = 18;
    const logoMarginRight = 10;
    const column1StartX = 10;
    const column2StartX = pageWidth / 2;
    const columnWidth = pageWidth / 2 - 20;

    // Cabeçalho
    pdf.setFont("Helvetica", "bold");
    pdf.setFontSize(15);
    pdf.text("Armazém JC7", 10, 15);
    pdf.setFont("Helvetica", "normal");
    pdf.setFontSize(13);
    pdf.text("Rodovia Padre Manoel da Nobrega N°83103", 10, 20);
    pdf.text("(13) 3406-1041", 10, 25);
    pdf.addImage(
      JC7Logo,
      "PNG",
      pageWidth - logoWidth - logoMarginRight,
      10,
      logoWidth,
      logoHeight
    );

    // Título
    pdf.setFontSize(16);
    pdf.text(
      `BILHETE DE PESAGEM N° ${carregamento.ID_CARREGAMENTO}`,
      pageWidth / 2,
      40,
      "center"
    );

    // Linha divisória
    pdf.setDrawColor(0);
    pdf.line(10, 45, pageWidth - 10, 45);

    let startY = 50;

    // Motorista e Veículo
    let motoristaEndY = addSectionWithBoxMotorista(
      pdf,
      "Motorista",
      formatMotoristaDetails(carregamento),
      column1StartX,
      startY,
      columnWidth
    );
    let veiculoEndY = addSectionWithBoxVeiculo(
      pdf,
      "Veículo",
      formatPlacaDetails(carregamento),
      column2StartX,
      startY,
      columnWidth
    );

    // Navio e Produto
    startY = Math.max(motoristaEndY, veiculoEndY) + 1;
    let navioEndY = addSectionWithBox(
      pdf,
      "Navio",
      carregamento.NOME_NAVIO,
      column1StartX,
      startY,
      columnWidth
    );
    let produtoEndY = addSectionWithBox(
      pdf,
      "Produto",
      carregamento.PRODUTO,
      column2StartX,
      startY,
      columnWidth
    );

    // Cliente e Transportadora
    startY = Math.max(navioEndY, produtoEndY) + 1;
    let clientEndY = addSectionWithBox(
      pdf,
      "Cliente",
      carregamento.NOME_REDUZIDO,
      column1StartX,
      startY,
      columnWidth
    );
    let transportadoraEndY = addSectionWithBox(
      pdf,
      "Transportadora",
      carregamento.NOME_REDUZIDO_TRANSP,
      column2StartX,
      startY,
      columnWidth
    );

    // DI e Pedido
    startY = Math.max(clientEndY, transportadoraEndY) + 1;
    let diEndY = addSectionWithBox(
      pdf,
      "DI",
      carregamento.NUMERO_DOC,
      column1StartX,
      startY,
      columnWidth
    );
    let pedidoEndY = addSectionWithBox(
      pdf,
      "Pedido",
      carregamento.NR_PEDIDO || "Sem Registro",
      column2StartX,
      startY,
      columnWidth
    );

    // Peso Bruto e Tara
    startY = Math.max(diEndY, pedidoEndY) + 1;
    let pesoBrutoEndY = addSectionWithBox4(
      pdf,
      "Peso Bruto",
      formatPesoBrutoDetails(carregamento),
      column1StartX,
      startY,
      columnWidth
    );
    let pesoTaraEndY = addSectionWithBox4(
      pdf,
      "Peso Tara",
      formatPesoTaraDetails(carregamento),
      column2StartX,
      startY,
      columnWidth
    );

    // Diferença de Peso e Peso Líquido
    startY = Math.max(pesoBrutoEndY, pesoTaraEndY) + 1;
    let diferencaPesoEndY = addSectionWithBox(
      pdf,
      "Diferença de Peso",
      formatDiferencaPesoDetails(carregamento),
      column1StartX,
      startY,
      columnWidth
    );
    let pesoLiquidoEndY = addSectionWithBox(
      pdf,
      "Peso Líquido",
      formatPesoLiquidoDetails(carregamento),
      column2StartX,
      startY,
      columnWidth
    );

    // Tipo de Operação e Nota Fiscal
    startY = Math.max(diferencaPesoEndY, pesoLiquidoEndY) + 1;
    addSectionWithBox(
      pdf,
      "Tipo de Operação",
      `${carregamento.TIPO_FLUXO} DE ${carregamento.PRODUTO}`,
      column1StartX,
      startY,
      columnWidth
    );
    addSectionWithBox(
      pdf,
      "Nota Fiscal",
      formatNotaFiscalDetails(carregamento),
      column2StartX,
      startY,
      columnWidth
    );

    // Preparando para seção de Assinaturas
    const signatureSectionStartY = pageHeight - 53; // Espaço adequado para a seção de assinaturas
    const signatureSectionHeight = 40; // Altura da seção de assinaturas

    // Assinaturas em duas colunas
    addSignatureSection(
      pdf,
      " Assinatura da Empresa",
      " JC7 Terminais Portuários e Transportes Ltda",
      column1StartX,
      signatureSectionStartY,
      columnWidth,
      signatureSectionHeight
    );
    addSignatureSectionMotorista(
      pdf,
      " Assinatura do Motorista",
      carregamento.NOME_MOTORISTA,
      formatarCPF(carregamento.CPF_MOTORISTA),
      column2StartX,
      signatureSectionStartY,
      columnWidth,
      signatureSectionHeight
    );

    // Linha divisória e Footer
    pdf.setDrawColor(0);
    pdf.line(10, 283, pageWidth - 10, 283);
    pdf.setFontSize(9);
    pdf.text(
      "Sistema de Pesagem - CONFIANTEC BALANÇAS\nwww.jc7terminais.com.br",
      pageWidth / 2,
      pageHeight - 10,
      "center"
    );

    // Salvar o PDF
    pdf.save(
      `Carregamento-${carregamento.ID_CARREGAMENTO}-${carregamento.TIPO_FLUXO}.pdf`
    );
  };

  function addSectionWithBox4(
    pdf,
    title,
    content,
    startX,
    startY,
    width,
    boldContent = false
  ) {
    const cornerRadius = 2; // Raio para os cantos arredondados
    const lineHeight = 6; // Altura da linha para melhor controle
    const sectionHeight = 25; // Altura total da seção para melhor controle

    pdf.setFontSize(11);
    pdf.setFont("Helvetica", "normal"); // Sempre definir fonte em negrito para o título

    // Posicionar o título à esquerda na caixa
    const titleX = startX + 2; // Pequeno deslocamento da borda esquerda
    pdf.text(`${title}:`, titleX, startY + lineHeight); // Ajustar posição vertical

    // Configurar a fonte para o conteúdo
    pdf.setFont("Helvetica", "bold"); // Voltar para fonte normal para o conteúdo
    pdf.setFontSize(12); // Definir tamanho da fonte para o conteúdo

    // Centralizar o conteúdo na caixa
    const contentWidth =
      (pdf.getStringUnitWidth(content) * pdf.internal.getFontSize()) /
      pdf.internal.scaleFactor;
    const contentX = startX + (width - contentWidth) / 2 + 16; // Calcular a posição x para centralizar o conteúdo

    pdf.text(content, contentX, startY + lineHeight * 2.5); // Ajustar posição vertical

    pdf.setDrawColor(0);
    pdf.setLineWidth(0.2); // Definir a largura da linha para a caixa
    // Desenhar um retângulo arredondado ao redor da seção
    pdf.roundedRect(
      startX,
      startY,
      width,
      sectionHeight,
      cornerRadius,
      cornerRadius,
      "S"
    );
    return startY + sectionHeight + lineHeight; // Retornar a posição y após a seção para uso posterior
  }

  function addSectionWithBoxMotorista(
    pdf,
    title,
    content,
    startX,
    startY,
    width,
    boldContent = false
  ) {
    const cornerRadius = 2; // Raio para os cantos arredondados
    const lineHeight = 6; // Altura da linha para melhor controle
    const sectionHeight = 23; // Altura total da seção para melhor controle

    pdf.setFontSize(11);
    pdf.setFont("Helvetica", "normal"); // Sempre definir fonte em negrito para o título

    // Posicionar o título à esquerda na caixa
    const titleX = startX + 2; // Pequeno deslocamento da borda esquerda
    pdf.text(`${title}:`, titleX, startY + lineHeight); // Ajustar posição vertical

    // Configurar a fonte para o conteúdo
    pdf.setFont("Helvetica", "bold"); // Voltar para fonte normal para o conteúdo
    pdf.setFontSize(12); // Definir tamanho da fonte para o conteúdo

    // Centralizar o conteúdo na caixa
    const contentWidth =
      (pdf.getStringUnitWidth(content) * pdf.internal.getFontSize()) /
      pdf.internal.scaleFactor;
    const contentX = startX + (width - contentWidth) / 2 + 20; // Calcular a posição x para centralizar o conteúdo

    pdf.text(content, contentX, startY + lineHeight * 2.5); // Ajustar posição vertical

    pdf.setDrawColor(0);
    pdf.setLineWidth(0.2); // Definir a largura da linha para a caixa
    // Desenhar um retângulo arredondado ao redor da seção
    pdf.roundedRect(
      startX,
      startY,
      width,
      sectionHeight,
      cornerRadius,
      cornerRadius,
      "S"
    );
    return startY + sectionHeight + lineHeight; // Retornar a posição y após a seção para uso posterior
  }

  function addSectionWithBoxVeiculo(
    pdf,
    title,
    content,
    startX,
    startY,
    width,
    boldContent = false
  ) {
    const cornerRadius = 2; // Raio para os cantos arredondados
    const lineHeight = 6; // Altura da linha para melhor controle
    const sectionHeight = 23; // Altura total da seção para melhor controle

    pdf.setFontSize(11);
    pdf.setFont("Helvetica", "normal"); // Sempre definir fonte em negrito para o título

    // Posicionar o título à esquerda na caixa
    const titleX = startX + 2; // Pequeno deslocamento da borda esquerda
    pdf.text(`${title}:`, titleX, startY + lineHeight); // Ajustar posição vertical

    // Configurar a fonte para o conteúdo
    pdf.setFont("Helvetica", "bold"); // Voltar para fonte normal para o conteúdo
    pdf.setFontSize(12); // Definir tamanho da fonte para o conteúdo

    // Centralizar o conteúdo na caixa
    const contentWidth =
      (pdf.getStringUnitWidth(content) * pdf.internal.getFontSize()) /
      pdf.internal.scaleFactor;
    const contentX = startX + (width - contentWidth) / 2 + 37; // Calcular a posição x para centralizar o conteúdo

    pdf.text(content, contentX, startY + lineHeight * 2.5); // Ajustar posição vertical

    pdf.setDrawColor(0);
    pdf.setLineWidth(0.2); // Definir a largura da linha para a caixa
    // Desenhar um retângulo arredondado ao redor da seção
    pdf.roundedRect(
      startX,
      startY,
      width,
      sectionHeight,
      cornerRadius,
      cornerRadius,
      "S"
    );
    return startY + sectionHeight + lineHeight; // Retornar a posição y após a seção para uso posterior
  }

  // Funções auxiliares

  function formatMotoristaDetails(carregamento) {
    return `${carregamento.NOME_MOTORISTA}\nCPF: ${formatarCPF(
      carregamento.CPF_MOTORISTA
    )}`;
  }

  function formatPlacaDetails(carregamento) {
    return `Cavalo: ${carregamento.PLACA_CAVALO}    Carreta: ${
      carregamento.PLACA_CARRETA || " N/A"
    }\nCarreta 2: ${carregamento.PLACA_CARRETA2 || " N/A"}    Carreta 3: ${
      carregamento.PLACA_CARRETA3 || " N/A"
    } `;
  }

  function formatPesoBrutoDetails(carregamento) {
    const dataBruto = carregamento.DATA_BRUTO
      ? new Date(carregamento.DATA_BRUTO).toLocaleString("pt-BR", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          timeZone: "America/Sao_Paulo",
        })
      : "Aguardando";
    const pesoBruto = carregamento.PESO_BRUTO
      ? carregamento.PESO_BRUTO.toLocaleString("pt-BR")
      : "0";
    return `Data e Hora: ${dataBruto}\nPESO: ${pesoBruto} KG`;
  }

  function formatPesoTaraDetails(carregamento) {
    const dataTara = carregamento.DATA_TARA
      ? new Date(carregamento.DATA_TARA).toLocaleString("pt-BR", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          timeZone: "America/Sao_Paulo",
        })
      : "Aguardando";
    const pesoTara = carregamento.PESO_TARA
      ? carregamento.PESO_TARA.toLocaleString("pt-BR")
      : "0";
    return `Data e Hora: ${dataTara}\nPESO: ${pesoTara} KG`;
  }

  function formatDiferencaPesoDetails(carregamento) {
    if (
      carregamento.PESO_BRUTO &&
      carregamento.PESO_TARA &&
      carregamento.PESO_NOTA_FISCAL
    ) {
      const diferencaDePeso =
        carregamento.PESO_BRUTO -
        carregamento.PESO_TARA -
        carregamento.PESO_NOTA_FISCAL;
      const porcentagemDiferenca =
        (diferencaDePeso / carregamento.PESO_NOTA_FISCAL) * 100;
      return `${diferencaDePeso.toLocaleString(
        "pt-BR"
      )} KG (${porcentagemDiferenca.toFixed(2).replace(".", ",")}%)`;
    } else {
      return "Sem Peso";
    }
  }

  function formatPesoLiquidoDetails(carregamento) {
    const pesoLiquido =
      carregamento.PESO_BRUTO && carregamento.PESO_TARA
        ? carregamento.PESO_BRUTO - carregamento.PESO_TARA
        : 0;
    return `${pesoLiquido.toLocaleString("pt-BR")} KG`;
  }

  function formatNotaFiscalDetails(carregamento) {
    const pesoNotaFiscal = carregamento.PESO_NOTA_FISCAL
      ? carregamento.PESO_NOTA_FISCAL.toLocaleString("pt-BR")
      : "0";
    const numeroNotaFiscal = carregamento.NUMERO_NOTA_FISCAL || "0"; // Se número for nulo, retorna "0"
    return `Número: ${numeroNotaFiscal}, PESO: ${pesoNotaFiscal} KG`;
  }

  // Função para adicionar máscara ao CPF
  const formatarCPF = (cpf) => {
    return cpf
      .replace(/\D/g, "") // Remove todos os caracteres não numéricos
      .replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4"); // Adiciona a máscara
  };

  function addSectionWithBox(
    pdf,
    title,
    content,
    startX,
    startY,
    width,
    boldContent = false
  ) {
    const cornerRadius = 2; // Define the radius for the rounded corners
    const lineHeight = 6; // Define the line height for better control
    const sectionHeight = 18; // Define the section height for better control

    pdf.setFontSize(11);
    pdf.setFont("Helvetica", "normal"); // Sempre definir fonte em negrito para o título

    // Posicionar o título à esquerda na caixa
    const titleX = startX + 2; // Pequeno deslocamento da borda esquerda
    pdf.text(`${title}:`, titleX, startY + lineHeight); // Ajustar posição vertical

    // Configurar a fonte para o conteúdo
    pdf.setFont("Helvetica", "bold"); // Voltar para fonte normal para o conteúdo
    pdf.setFontSize(12); // Definir tamanho da fonte para o conteúdo

    // Centralizar o conteúdo na caixa
    const contentWidth =
      (pdf.getStringUnitWidth(content) * pdf.internal.getFontSize()) /
      pdf.internal.scaleFactor;
    const contentX = startX + (width - contentWidth) / 2; // Calcular a posição x para centralizar o conteúdo

    pdf.text(content, contentX, startY + lineHeight * 2.5); // Ajustar posição vertical

    pdf.setDrawColor(0);
    pdf.setLineWidth(0.2); // Definir a largura da linha para a caixa
    // Desenhar um retângulo arredondado ao redor da seção
    pdf.roundedRect(
      startX,
      startY,
      width,
      sectionHeight,
      cornerRadius,
      cornerRadius,
      "S"
    );
    return startY + sectionHeight + lineHeight; // Retornar a posição y após a seção para uso posterior
  }

  function addSignatureSection(pdf, title, content, startX, startY, width) {
    pdf.setFontSize(10);
    pdf.text(`${title}:`, startX, startY);
    pdf.text(content, startX, startY + 8);
    pdf.setDrawColor(0);
    pdf.setLineWidth(0.2);
    pdf.rect(startX, startY - 5, width, 40, "S");
  }

  function addSignatureSectionMotorista(
    pdf,
    title,
    name,
    cpf,
    startX,
    startY,
    width
  ) {
    const lineHeight = 10;
    pdf.setFontSize(10);
    pdf.text(`${title}:`, startX, startY);
    pdf.setFont("Helvetica", "normal");
    pdf.setFontSize(9);
    pdf.text(name, startX, startY + 5);
    pdf.text(`CPF: ${cpf}`, startX, startY + 9);
    pdf.setDrawColor(0);
    pdf.setLineWidth(0.2);
    pdf.rect(startX, startY - 5, width, 3 * lineHeight + 10, "S");
  }

  const getDate = () => {
    const date = new Date();
    date.setHours(date.getHours() - 3);
    return date.toISOString().slice(0, 19).replace("T", " ");
  };

  const handlePedidoChange = (e) => {
    const selectedPedidoMic = e.target.value;
    setPedidoMic(selectedPedidoMic);

    // Chama getDestino e getTransportadora com o ID do pedido selecionado
    getDestino(selectedPedidoMic);
    getTransportadora(selectedPedidoMic); // Adiciona esta chamada
  };

  const getOperacoes = () => {
    Axios.get("https://portal.jc7transportes.com.br/api/operacao").then(
      (response) => {
        setOperacoesList(response.data);
        console.log(response.data);
        getCargas();
      }
    );
  };

  const getDestino = (idPedido) => {
    Axios.get(`https://portal.jc7transportes.com.br/api/destino/${idPedido}`)
      .then((res) => {
        // A resposta direta contém o nome do destino
        const destinoInfo = res.data;
        setDestino(destinoInfo.NOME_DESTINO); // Atualiza o estado do destino com o nome obtido
      })
      .catch((error) => {
        console.error("Erro ao buscar informações do destino:", error);
        showAlert("Erro ao carregar informações do destino.", "error");
      });
  };

  const getCargas = (id) => {
    Axios.get(
      `https://portal.jc7transportes.com.br/api/carga/busca/${id}`
    ).then(function (res) {
      setDocs(res.data);
      console.log(res.data);
      getPedido(id);
    });
  };

  const getTransp = () => {
    Axios.get(`https://portal.jc7transportes.com.br/api/transportadora`).then(
      function (res) {
        setTransportadoras(res.data);
        console.log(res.data);
      }
    );
  };

  const getBigBag = () => {
    Axios.get(
      `https://portal.jc7transportes.com.br/api/PesagemAvulsa/bigbag`
    ).then(function (res) {
      setBigBags(res.data);
      console.log(res.data);
    });
  };

  const getPedido = (id) => {
    Axios.get(
      `https://portal.jc7transportes.com.br/api/pesageminicial/pedidos/${id}`
    ).then(function (res) {
      setPedidosByDoc(res.data);
      console.log(res.data);
    });
  };

  const getTransportadora = (idPedido) => {
    Axios.get(
      `https://portal.jc7transportes.com.br/api/transportadoras/pedido/${idPedido}`
    )
      .then((res) => {
        // Assume que a resposta é um array de transportadoras
        setTransportadoras(res.data); // Atualiza o estado com as transportadoras recebidas
      })
      .catch((error) => {
        console.error("Erro ao buscar transportadoras para o pedido:", error);
        showAlert("Erro ao carregar transportadoras para o pedido.", "error");
      });
  };

  const getSaldodoc = (id) => {
    Axios.get(
      `https://portal.jc7transportes.com.br/api/pesageminicial/saldodoc/${id}`
    )
      .then(function (res) {
        setSaldoTons(res.data);
        // Assuming you want the first element from the response
        if (res.data.length > 0) {
          setSaldoTons(res.data[0].SALDO_TONS);
          setPorcentagem(res.data[0].PORCENTAGEM); // Set the PORCENTAGEM value
        }
      })
      .catch(function (error) {
        console.error("Error fetching SALDO_TONS:", error);
      });
  };

  const { enqueueSnackbar } = useSnackbar();
  const showAlert = (txt, variant) => {
    enqueueSnackbar(txt, { variant: variant });
  };

  let { nome } = useParams();
  let { cpf } = useParams();
  let { cnh } = useParams();
  let { id } = useParams();

  // Função para buscar motorista
  const getMotorista = () => {
    const cpfSemMascara = cpfMotorista.replace(/\D/g, "");

    if (!cpfSemMascara || cpfSemMascara.length < 11) {
      alert("CPF inválido ou não informado corretamente.");
      setNomeMotorista("");
      setCodMotorista(null);
      return;
    }

    Axios.get(
      `https://portal.jc7transportes.com.br/api/motorista/busca/${cpfSemMascara}`
    )
      .then((res) => {
        if (res.data && res.data.length > 0) {
          setNomeMotorista(res.data[0].NOME_MOTORISTA);
          setCodMotorista(res.data[0].COD_MOTORISTA);
        } else {
          setNomeMotorista("");
          setCodMotorista(null);
          setShowModal(true); // Exibe o modal caso o motorista não seja encontrado
        }
      })
      .catch((error) => {
        console.error("Erro ao buscar motorista:", error);
        alert("Erro ao buscar motorista.");
      });
  };

  // Atualiza o peso líquido sempre que o bruto ou tara mudar
  useEffect(() => {
    if (bruto && tara) {
      const resultado = parseFloat(bruto) - parseFloat(tara);
      setPesoLiquido(resultado);
    } else {
      setPesoLiquido(null); // Caso algum valor esteja vazio
    }
  }, [bruto, tara]); // A dependência garante que a função seja executada sempre que 'bruto' ou 'tara' mudarem

  // Função chamada sempre que o usuário digita no campo
  const handleCpfChange = (e) => {
    const valor = e.target.value;
    setCpfMotorista(formatarCPF(valor)); // Atualiza o campo com máscara
    setCpfSemMascara(valor.replace(/\D/g, "")); // Atualiza o CPF sem máscara
  };

  const handleTipoPesoChange = (value) => {
    setTipoPeso((prev) => {
      if (prev.includes(value)) {
        return prev.filter((item) => item !== value); // Remove a opção se já estiver selecionada
      } else {
        return [...prev, value]; // Adiciona a opção se ainda não estiver selecionada
      }
    });
  };

  return (
    <>
      <Navbar veiculos />
      <Header />
      <Brackground />
      <Container>
        <div className={style.content}>
          <div className={style.nav}>
            <div
              className={style.navbar}
              onClick={() => navigate("/pesagemavulsa/historicopesagemavulsa")}
            >
              Pesagem Avulsa
            </div>
            <div className={style.active}>Registrar Carregamento</div>
          </div>

          <div className={style.align}>
            <div className="columns">
              {/* Dados do Motorista */}
              <div className="column is-4">
                <fieldset className={style.fieldset}>
                  <legend className={style.legend}>Dados do Motorista</legend>
                  <div className={style.cardMotorista}>
                    <div className={style.cabecarioMotorista}>
                      <div className={style.motoristaInfo}>
                        <strong>Motorista:</strong>{" "}
                        {nomeMotorista
                          ? nomeMotorista
                          : "Motorista não encontrado"}
                      </div>
                      <div className={style.cpfInfo}>
                        <strong>CPF:</strong>
                        <input
                          type="text"
                          value={cpfMotorista}
                          onChange={handleCpfChange}
                          placeholder="Informe o CPF do motorista"
                        />
                      </div>
                      <button
                        onClick={getMotorista}
                        className={style.btnBuscar}
                      >
                        Buscar Motorista
                      </button>
                    </div>
                  </div>
                </fieldset>

                {/* Tipo de Pesagem */}
                <fieldset className={style.fieldset}>
                  <legend className={style.legend}>Tipo de Pesagem</legend>
                  <div className={style.control}>
                    <label className={style.input}>
                      <input
                        type="radio"
                        onChange={(e) => [
                          setTipopesagem(e.target.value),
                          validaTipo(e.target.value),
                        ]}
                        value="G"
                        name="tipoPesagem"
                        checked={tipopesagem === "G"}
                        aria-label="Granel"
                      />
                      GRANEL
                    </label>
                    <label className={style.input}>
                      <input
                        type="radio"
                        onChange={(e) => [
                          setTipopesagem(e.target.value),
                          validaTipo(e.target.value),
                        ]}
                        value="B"
                        name="tipoPesagem"
                        checked={tipopesagem === "B"}
                        aria-label="Big Bag"
                      />
                      BIG BAG
                    </label>
                    <label className={style.input}>
                      <input
                        type="radio"
                        onChange={(e) => [
                          setTipopesagem(e.target.value),
                          validaTipo(e.target.value),
                        ]}
                        value="C"
                        name="tipoPesagem"
                        checked={tipopesagem === "C"}
                        aria-label="Container"
                      />
                      CONTÊINER
                    </label>
                  </div>
                </fieldset>

                {/* Seleção de Navio */}
                <fieldset className={style.fieldset_altt}>
                  <legend className={style.legendd}>Cliente e Produto</legend>
                  <input
                    type="text"
                    id="cliente"
                    placeholder="Cliente"
                    value={cliente}
                    onChange={(e) => setCliente(e.target.value.toUpperCase())}
                  />
                  <input
                    type="text"
                    id="produto"
                    placeholder="Produto"
                    value={produto}
                    onChange={(e) => setProduto(e.target.value.toUpperCase())}
                  />
                </fieldset>
              </div>

              {/* Dados do Veículo */}
              <div className="column is-4">
                <fieldset className={style.fieldset_alt}>
                  <legend className={style.legend}>Dados do Veículo</legend>

                  <div className={style.placas}>
                    <div className={style.placaContainer}>
                      <label htmlFor="placaCavalo">Cavalo</label>
                      <input
                        type="text"
                        id="placaCavalo"
                        placeholder="Ex: AAA1234"
                        value={placacavalo}
                        onChange={(e) =>
                          setPlacacavalo(e.target.value.toUpperCase())
                        }
                      />
                    </div>

                    <div className={style.placaContainer}>
                      <label htmlFor="placa1">Carreta 1</label>
                      <input
                        type="text"
                        id="placa1"
                        placeholder="Ex: AAA1234"
                        value={placa1}
                        onChange={(e) =>
                          setPlaca1(e.target.value.toUpperCase())
                        }
                      />
                    </div>

                    <div className={style.placaContainer}>
                      <label htmlFor="placa2">Doly</label>
                      <input
                        type="text"
                        id="placa2"
                        placeholder="Ex: AAA1234"
                        value={placa2}
                        onChange={(e) =>
                          setPlaca2(e.target.value.toUpperCase())
                        }
                      />
                    </div>

                    <div className={style.placaContainer}>
                      <label htmlFor="placa3">Carreta 2</label>
                      <input
                        type="text"
                        id="placa3"
                        placeholder="Ex: AAA1234"
                        value={placa3}
                        onChange={(e) =>
                          setPlaca3(e.target.value.toUpperCase())
                        }
                      />
                    </div>
                  </div>
                </fieldset>

                {/* Transportadora */}
                <fieldset className={style.fieldset_alt}>
                  <legend className={style.legend}>Transportadora</legend>
                  <select
                    onChange={(e) =>
                      setTransportadora(parseInt(e.target.value, 10))
                    }
                  >
                    <option disabled selected>
                      Escolha opção
                    </option>
                    {transportadoras.map((val) => (
                      <option value={val.COD_TRANSP} key={val.COD_TRANSP}>
                        {val.NOME_TRANSP}
                      </option>
                    ))}
                  </select>
                </fieldset>

                <fieldset className={style.fieldset}>
                  <legend className={style.legend}>
                    Escolha o Tipo de Peso
                  </legend>
                  <div className={style.control}>
                    <label className={style.input}>
                      <input
                        type="checkbox"
                        onChange={() => handleTipoPesoChange("tara")}
                        value="tara"
                        checked={tipoPeso.includes("tara")}
                      />
                      Peso Tara
                    </label>
                    <label className={style.input}>
                      <input
                        type="checkbox"
                        onChange={() => handleTipoPesoChange("bruto")}
                        value="bruto"
                        checked={tipoPeso.includes("bruto")}
                      />
                      Peso Bruto
                    </label>
                  </div>
                </fieldset>
              </div>

              {/* Informações da Operação */}
              <div className="column is-4">
                {/* Exibir Peso Tara se estiver selecionado */}
                {tipoPeso.includes("tara") && (
                  <fieldset className={style.fieldset}>
                    <legend className={style.legend}>Peso Vazio (Tara)</legend>
                    <Input
                      type="text"
                      placeholder="Insira o Peso em KG"
                      value={tara}
                      onChange={(e) => setTara(e.target.value)}
                    />
                  </fieldset>
                )}

                {/* Exibir Peso Bruto se estiver selecionado */}
                {tipoPeso.includes("bruto") && (
                  <fieldset className={style.fieldset}>
                    <legend className={style.legend}>Peso Cheio (Bruto)</legend>
                    <Input
                      type="text"
                      placeholder="Insira o Peso em KG"
                      value={bruto}
                      onChange={(e) => setBruto(e.target.value)}
                    />
                  </fieldset>
                )}

                {/* Resultado: Peso Líquido */}
                <fieldset className={style.fieldset}>
                  <legend className={style.legend}>Peso Líquido</legend>
                  <input
                    type="text"
                    value={
                      pesoLiquido !== null
                        ? `${new Intl.NumberFormat("pt-BR").format(
                            pesoLiquido
                          )} KG`
                        : "Aguardando valores"
                    }
                    disabled
                    className={style.inputField}
                  />
                </fieldset>

                <div className={style.button}>
                  <SubmitButton
                    text={"Cadastrar"}
                    onClick={validaDados}
                    onKeyPress={(e) => validaTecla(e)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default function IntegrationNotistack() {
  return (
    <SnackbarProvider
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      maxSnack={3}
      autoHideDuration={2500}
    >
      <PesagemAvulsa />
    </SnackbarProvider>
  );
}
